import axios from "axios";

export const getInfo = async (status, website, page) => {
  try {
    const { data } = await axios.get(
      `https://${window.location.hostname}/api/sms/getAll?status=${status}&website=${website}` +
        (page ? `&page=${page}` : "")
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addNewOffer = async (offer) => {
  try {
    await axios.patch(`https://${window.location.hostname}/api/offer/update`, {
      offer,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const deleteOffer = async (offer) => {
  try {
    await axios.patch(`https://${window.location.hostname}/api/offer/delete`, {
      offer,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const getAllOffers = async () => {
  try {
    const { data } = await axios.get(
      `https://${window.location.hostname}/api/offer/all`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const login = async (password) => {
  try {
    const { data } = await axios.post(
      `https://${window.location.hostname}/api/login/try`,
      {
        password,
      }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
