import { useEffect, useState } from "react";
import { addNewOffer, deleteOffer } from "../../services/axios";
import { toast } from "react-toastify";

export const AddOffer = () => {
  const [newOffer, setNewOffer] = useState("");

  // useEffect(() => {
  //   if (!localStorage.getItem("passwordValid")) {
  //     window.location.href = "offerwall";

  //   }
  // }, []);

  const notify = () => toast("Successfully");

  const notifyWrong = () => toast("Oh! Something wrong...");

  return (
    <div className="new">
      <p className="new__text">Add Offer</p>
      <input
        className="new__input"
        type="text"
        placeholder="type something..."
        value={newOffer}
        onChange={(e) => {
          setNewOffer(e.target.value);
        }}
      />
      <button
        type="button"
        className="new__btn"
        disabled={!newOffer}
        onClick={() => {
          const res = addNewOffer(newOffer);
          if (res) {
            notify();
          } else {
            notifyWrong();
          }
          setNewOffer("");
        }}
      >
        Додати
      </button>
      <button
        type="button"
        className="new__btn"
        disabled={!newOffer}
        onClick={() => {
          const res = deleteOffer(newOffer);
          if (res) {
            notify();
          } else {
            notifyWrong();
          }
          setNewOffer("");
        }}
      >
        Видалити
      </button>
    </div>
  );
};
