import axios from "axios";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Download = () => {
  // useEffect(() => {
  //   if (!localStorage.getItem("passwordValid")) {
  //     window.location.href = "offerwall";
  //   }
  // }, []);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [currentDate, setCurrentDate] = useState("");

  const handleDownload = async (status) => {
    let data = {};

    if (startDate && endDate) {
      data = { startDate, endDate };
    }
    try {
      // Send a request to the backend API endpoint to download the database
      const response = await axios.post(
        `https://${window.location.hostname}/api/excel/download`,
        { status: status, ...data },
        {
          responseType: "blob", // Ensure response type is blob for file download
        }
      );

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary <a> element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `database-${status}.xlsx`); // Specify the filename
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading database:", error);
    }
  };
  return (
    <>
      <div className="download__top--wrapper">
        <div className="download__top--thumb">
          <p className="download__text">from</p>
          {/* <input
            type="text"
            className="download__input"
            value={startDate}
            placeholder="year-dd-mm"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          /> */}
          <DatePicker
            className="download__input"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
          />
        </div>
        <div className="download__top--thumb">
          <p className="download__text">to</p>
          {/* <input
            type="text"
            className="download__input"
            value={endDate}
            placeholder="year-dd-mm"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          /> */}
          <DatePicker
            selected={endDate}
            className="download__input"
            onChange={(date) => {
              setEndDate(date);
            }}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
          />
        </div>
      </div>
      {/* <div className="download__or--wrapper">
        <div className="download__or--line"></div>
        <p className="download__or">or</p>
        <div className="download__or--line"></div>
      </div> */}
      {/* <div className="download__middle">
        <p className="download__middle--text">day</p> */}
      {/* <input
          type="text"
          className="download__input--bottom"
          placeholder="year-dd-mm"
          value={currentDate}
          onChange={(e) => {
            setCurrentDate(e.target.value);
          }}
        /> */}
      {/* <DatePicker
          selected={currentDate}
          className="download__input--bottom"
          onChange={(date) => {
            setCurrentDate(date);
          }}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select a date"
        /> */}
      {/* </div> */}
      <div className="button__wrapper--download">
        <button
          type="button"
          onClick={() => {
            handleDownload("all");
          }}
          className="download__button"
        >
          Download All
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.39393 12.6061L8.5 12.7121L8.60607 12.6061L12.906 8.30618C13.3524 7.85969 13.3588 7.13777 12.9202 6.68353C12.4706 6.21787 11.7265 6.21134 11.2688 6.66904L9.65 8.28787V1.5C9.65 0.864873 9.13513 0.35 8.5 0.35C7.86487 0.35 7.35 0.864873 7.35 1.5V8.28787L5.73118 6.66904C5.27348 6.21134 4.52941 6.21787 4.07981 6.68353C3.64123 7.13777 3.64756 7.85969 4.09404 8.30617L8.39393 12.6061ZM0.35 14C0.35 15.4636 1.53645 16.65 3 16.65H14C15.4636 16.65 16.65 15.4636 16.65 14V12.5C16.65 11.8649 16.1351 11.35 15.5 11.35C14.8649 11.35 14.35 11.8649 14.35 12.5V14.35H4C3.25442 14.35 2.65 13.7456 2.65 13V12.5C2.65 11.8649 2.13513 11.35 1.5 11.35C0.864873 11.35 0.35 11.8649 0.35 12.5V14Z"
              fill="black"
              stroke="black"
              strokeWidth="0.3"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => {
            handleDownload("lead");
          }}
          className="download__button"
        >
          Download Leads
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.39393 12.6061L8.5 12.7121L8.60607 12.6061L12.906 8.30618C13.3524 7.85969 13.3588 7.13777 12.9202 6.68353C12.4706 6.21787 11.7265 6.21134 11.2688 6.66904L9.65 8.28787V1.5C9.65 0.864873 9.13513 0.35 8.5 0.35C7.86487 0.35 7.35 0.864873 7.35 1.5V8.28787L5.73118 6.66904C5.27348 6.21134 4.52941 6.21787 4.07981 6.68353C3.64123 7.13777 3.64756 7.85969 4.09404 8.30617L8.39393 12.6061ZM0.35 14C0.35 15.4636 1.53645 16.65 3 16.65H14C15.4636 16.65 16.65 15.4636 16.65 14V12.5C16.65 11.8649 16.1351 11.35 15.5 11.35C14.8649 11.35 14.35 11.8649 14.35 12.5V14.35H4C3.25442 14.35 2.65 13.7456 2.65 13V12.5C2.65 11.8649 2.13513 11.35 1.5 11.35C0.864873 11.35 0.35 11.8649 0.35 12.5V14Z"
              fill="black"
              stroke="black"
              strokeWidth="0.3"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => {
            handleDownload("click");
          }}
          className="download__button"
        >
          Download Clicks
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.39393 12.6061L8.5 12.7121L8.60607 12.6061L12.906 8.30618C13.3524 7.85969 13.3588 7.13777 12.9202 6.68353C12.4706 6.21787 11.7265 6.21134 11.2688 6.66904L9.65 8.28787V1.5C9.65 0.864873 9.13513 0.35 8.5 0.35C7.86487 0.35 7.35 0.864873 7.35 1.5V8.28787L5.73118 6.66904C5.27348 6.21134 4.52941 6.21787 4.07981 6.68353C3.64123 7.13777 3.64756 7.85969 4.09404 8.30617L8.39393 12.6061ZM0.35 14C0.35 15.4636 1.53645 16.65 3 16.65H14C15.4636 16.65 16.65 15.4636 16.65 14V12.5C16.65 11.8649 16.1351 11.35 15.5 11.35C14.8649 11.35 14.35 11.8649 14.35 12.5V14.35H4C3.25442 14.35 2.65 13.7456 2.65 13V12.5C2.65 11.8649 2.13513 11.35 1.5 11.35C0.864873 11.35 0.35 11.8649 0.35 12.5V14Z"
              fill="black"
              stroke="black"
              strokeWidth="0.3"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => {
            handleDownload("reg");
          }}
          className="download__button"
        >
          Download Reg
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.39393 12.6061L8.5 12.7121L8.60607 12.6061L12.906 8.30618C13.3524 7.85969 13.3588 7.13777 12.9202 6.68353C12.4706 6.21787 11.7265 6.21134 11.2688 6.66904L9.65 8.28787V1.5C9.65 0.864873 9.13513 0.35 8.5 0.35C7.86487 0.35 7.35 0.864873 7.35 1.5V8.28787L5.73118 6.66904C5.27348 6.21134 4.52941 6.21787 4.07981 6.68353C3.64123 7.13777 3.64756 7.85969 4.09404 8.30617L8.39393 12.6061ZM0.35 14C0.35 15.4636 1.53645 16.65 3 16.65H14C15.4636 16.65 16.65 15.4636 16.65 14V12.5C16.65 11.8649 16.1351 11.35 15.5 11.35C14.8649 11.35 14.35 11.8649 14.35 12.5V14.35H4C3.25442 14.35 2.65 13.7456 2.65 13V12.5C2.65 11.8649 2.13513 11.35 1.5 11.35C0.864873 11.35 0.35 11.8649 0.35 12.5V14Z"
              fill="black"
              stroke="black"
              strokeWidth="0.3"
            />
          </svg>
        </button>
        <button
          type="button"
          onClick={() => {
            handleDownload("dep");
          }}
          className="download__button"
        >
          Download Dep
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.39393 12.6061L8.5 12.7121L8.60607 12.6061L12.906 8.30618C13.3524 7.85969 13.3588 7.13777 12.9202 6.68353C12.4706 6.21787 11.7265 6.21134 11.2688 6.66904L9.65 8.28787V1.5C9.65 0.864873 9.13513 0.35 8.5 0.35C7.86487 0.35 7.35 0.864873 7.35 1.5V8.28787L5.73118 6.66904C5.27348 6.21134 4.52941 6.21787 4.07981 6.68353C3.64123 7.13777 3.64756 7.85969 4.09404 8.30617L8.39393 12.6061ZM0.35 14C0.35 15.4636 1.53645 16.65 3 16.65H14C15.4636 16.65 16.65 15.4636 16.65 14V12.5C16.65 11.8649 16.1351 11.35 15.5 11.35C14.8649 11.35 14.35 11.8649 14.35 12.5V14.35H4C3.25442 14.35 2.65 13.7456 2.65 13V12.5C2.65 11.8649 2.13513 11.35 1.5 11.35C0.864873 11.35 0.35 11.8649 0.35 12.5V14Z"
              fill="black"
              stroke="black"
              strokeWidth="0.3"
            />
          </svg>
        </button>
      </div>
    </>
  );
};
