import { Route, Routes } from "react-router-dom";
import { SharedLayout } from "./components/Layout/SharedLayout";
import Table from "./components/Table/Table";
import { useEffect, useState } from "react";
import { AddOffer } from "./components/AddOffer/AddOffer";
import { Download } from "./components/Download/Download";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "./services/axios";

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [showSite, setShowSite] = useState(false);
  const [website, setWebsite] = useState(1);
  const [openSelect, setOpenSelect] = useState(false);

  // useEffect(() => {
  //   const storedLastAttemptTime = localStorage.getItem("lastAttemptTime");
  //   if (storedLastAttemptTime) {
  //     const currentTime = new Date().getTime();
  //     if (currentTime - parseInt(storedLastAttemptTime) > 600000) {
  //       setAttempts(0);
  //     } else {
  //       setAttempts(4);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("passwordValid")) {
      setShowSite(true);
    } else {
      setShowSite(false);
    }
  }, []);

  return (
    <>
      {window.location.hostname === "alrety.top" ||
      window.location.hostname === "yaribo.top" ? (
        <div
          onClick={(e) => {
            if (e.target.id !== "offer" && e.target.id !== "top") {
              setSortOpen(false);
            }

            if (
              e.target.id !== "select-header" &&
              e.target.id !== "select-svg" &&
              e.target.id !== "select-path"
            ) {
              setOpenSelect(false);
            }
          }}
        >
          {showSite ? (
            <>
              <Routes>
                <Route
                  path="/"
                  element={
                    <SharedLayout
                      website={website}
                      setWebsite={setWebsite}
                      openSelect={openSelect}
                      setOpenSelect={setOpenSelect}
                    />
                  }
                >
                  <Route
                    index
                    element={
                      <Table
                        offer={false}
                        status={"lead"}
                        setModalOpen={setModalOpen}
                        sortOpen={sortOpen}
                        setSortOpen={setSortOpen}
                        website={website}
                      />
                    }
                  />
                  <Route
                    path="click"
                    element={
                      <Table
                        offer={false}
                        status={"click"}
                        setModalOpen={setModalOpen}
                        sortOpen={sortOpen}
                        setSortOpen={setSortOpen}
                        website={website}
                      />
                    }
                  />
                  <Route
                    path="reg"
                    element={
                      <Table
                        offer={true}
                        status={"reg"}
                        setModalOpen={setModalOpen}
                        sortOpen={sortOpen}
                        setSortOpen={setSortOpen}
                        website={website}
                      />
                    }
                  />
                  <Route
                    path="dep"
                    element={
                      <Table
                        offer={true}
                        status={"dep"}
                        setModalOpen={setModalOpen}
                        sortOpen={sortOpen}
                        setSortOpen={setSortOpen}
                        website={website}
                      />
                    }
                  />
                  <Route path="addOffer" element={<AddOffer />} />
                  <Route
                    path="download"
                    element={<Download website={website} />}
                  />
                </Route>
              </Routes>
              {modalOpen && (
                <div
                  className="backdrop"
                  id="backdrop"
                  onClick={(e) => {
                    if (e.target.id === "backdrop") {
                      setModalOpen(false);
                    }
                  }}
                >
                  <div className="modal">
                    <p className="modal__text">
                      <span className="modal__accent">ID: </span>
                      {modalOpen.hash}
                    </p>
                    <p className="modal__text">
                      <span className="modal__accent">Phone: </span>
                      {modalOpen.phoneNumber}
                    </p>
                    <p className="modal__text">
                      <span className="modal__accent">Message: </span>
                      {modalOpen.message}
                    </p>
                    <p className="modal__text">
                      <span className="modal__accent">Link: </span>
                      {modalOpen.link}
                    </p>
                    <p className="modal__text">
                      <span className="modal__accent">Long Link: </span>
                      {modalOpen.longLink}
                    </p>
                    {modalOpen?.offer && (
                      <p className="modal__text">
                        <span className="modal__accent">Offer: </span>
                        {modalOpen.offer}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <ToastContainer theme="dark" />
            </>
          ) : (
            <div className="backdrop">
              <></>
              <div className="modal">
                <>
                  <input
                    className="new__input modal__password"
                    type="text"
                    placeholder="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    className="new__input modal__password--btn"
                    onClick={async () => {
                      // const currentTime = new Date().getTime();
                      // if (currentTime - lastAttemptTime > 600000) {
                      //   setAttempts(1);
                      //   setLastAttemptTime(currentTime);
                      //   localStorage.setItem("lastAttemptTime", currentTime);
                      // } else {
                      //   setAttempts((prevAttempts) => prevAttempts + 1);
                      //   localStorage.setItem("attempts", attempts + 1);
                      // }

                      if ((await login(password)) === "access!") {
                        localStorage.setItem("passwordValid", true);
                        setShowSite(true);
                      }
                    }}
                  >
                    Вперед
                  </button>
                </>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default App;
