import React, { useEffect, useState } from "react";
import { getAllOffers, getInfo } from "../../services/axios";

const Table = ({
  offer,
  status,
  setModalOpen,
  sortOpen,
  setSortOpen,
  website,
}) => {
  const [data, setData] = useState([]);
  // const [sort, setSort] = useState("all");
  const [offersToShow, setOffersToShow] = useState([]);
  const [page, setPage] = useState(1);

  const getData = async () => {
    const newData = await getInfo(status, website, page + 1);
    setData((prev) => {
      return [...prev, ...newData];
    });
  };

  // useEffect(() => {
  //   if (!localStorage.getItem("passwordValid")) {
  //     window.location.href = "offerwall";
  //   }
  // }, []);

  useEffect(() => {
    setPage(1);
    const getData = async () => {
      setData(await getInfo(status, website));
    };

    getData();
  }, [status, website]);

  useEffect(() => {
    const getOffer = async () => {
      const offersToAdd = await getAllOffers();
      setOffersToShow(offersToAdd?.offer);
    };

    getOffer();
  }, []);

  const sortDataByOffer = async (sort) => {
    if (sort === "all") {
      setData(await getInfo(status, website));
    } else {
      const dataToSort = await getInfo(status, website);
      setData(dataToSort.filter((item) => item.offer === sort));
    }
  };

  return (
    <>
      <div className="table__container">
        <div className="table__header">
          <div
            className="table__header--item table__header--left"
            style={{ width: offer ? "300px" : "450px" }}
          >
            ID
          </div>
          <div
            className={
              offer
                ? "table__header--item"
                : "table__header--item table__header--right"
            }
            style={{ width: offer ? "350px" : "500px" }}
          >
            Link
          </div>
          {offer && (
            <div
              id="offer"
              className="table__header--item table__header--right"
              onClick={(e) => {
                if (e.target.id === "offer") {
                  setSortOpen(true);
                }
              }}
              style={{ width: "300px", gap: "12px", cursor: "pointer" }}
            >
              Offer
              <svg
                width="16"
                height="10"
                viewBox="0 0 16 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.7531 0.439333C15.3388 1.02512 15.3388 1.97487 14.7531 2.56065L8 9.31371L1.24695 2.56065C0.66116 1.97487 0.66116 1.02512 1.24695 0.439333C1.83273 -0.146453 2.78248 -0.146453 3.36827 0.439333L8 5.07107L12.6317 0.439333C13.2175 -0.146453 14.1673 -0.146453 14.7531 0.439333Z"
                  fill="#ffffff"
                />
              </svg>
              {sortOpen && (
                <div className="modal__select">
                  <p className="modal__select--text" id="top">
                    Sort by
                  </p>
                  <div>
                    <div
                      onClick={() => {
                        sortDataByOffer("all");
                        setSortOpen(false);
                      }}
                      className="modal__select--item"
                    >
                      all
                    </div>
                    {offersToShow?.map((offerItem, index) => (
                      <div
                        key={index}
                        className="modal__select--item"
                        onClick={() => {
                          sortDataByOffer(offerItem);
                          setSortOpen(false);
                        }}
                      >
                        {offerItem}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {data?.map((item, index) => (
          <div
            className="table__body"
            key={index}
            onClick={() => {
              setModalOpen(item);
            }}
          >
            <div
              className="table__body--item"
              style={{ width: offer ? "300px" : "450px" }}
            >
              {item.hash}
            </div>
            <div
              className="table__body--item"
              style={{ width: offer ? "350px" : "500px" }}
            >
              {item.link}
            </div>
            {offer && (
              <div
                className="table__body--item"
                style={{ width: offer ? "300px" : "450px" }}
              >
                {item?.offer}
              </div>
            )}
          </div>
        ))}
        <button
          className="loadmore__button"
          type="button"
          onClick={() => {
            setPage((prev) => (prev += 1));
            getData();
          }}
        >
          Наступна сторінка
        </button>
      </div>
    </>
  );
};

export default Table;
